<template>
  <LoadingBar v-if="loading"></LoadingBar>
  <v-row v-else dense>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="title"
        :rules="rules.required"
        label="Name"
      ></v-text-field>
      <v-text-field v-model="number" label="Number"></v-text-field>
      <v-select
        :items="feed_classes"
        :rules="rules.required"
        label="Feed Category"
        no-data-text="No feed categories to select from"
        item-text="title"
        item-value="id"
        v-model="feed_class_id"
        clearable
      ></v-select>
      <v-select
        :items="filterFeedTypesByClass()"
        :rules="rules.required"
        label="Feed Type"
        no-data-text="No feed types to select from"
        item-text="title"
        item-value="id"
        v-model="feed_type_id"
      ></v-select>
      <v-text-field v-model="seed_brand" label="Seed Brand"></v-text-field>
      <v-text-field
        v-model="seed_genetics"
        label="Seed Genetics"
      ></v-text-field>
      <v-text-field v-model="additives" label="Additives"></v-text-field>
      <v-menu
        v-model="plantingDateMenu"
        :close-on-content-click="false"
        :nudge-right="20"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date_of_planting"
            label="Date of Planting"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date_of_planting"
          @input="plantingDateMenu = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="purchaseDateMenu"
        :close-on-content-click="false"
        :nudge-right="20"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date_of_purchase"
            label="Date of Purchase"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date_of_purchase"
          @input="purchaseDateMenu = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="estUseDateMenu"
        :close-on-content-click="false"
        :nudge-right="20"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="estimated_use_date"
            label="Estimated Use Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="estimated_use_date"
          @input="estUseDateMenu = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="harvestDatesMenu"
        :close-on-content-click="false"
        :nudge-right="20"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox
            v-model="harvestDates"
            multiple
            label="Harvest Dates"
            prepend-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-combobox>
        </template>
        <v-date-picker v-model="harvestDates" no-title multiple scrollable>
          <v-btn
            class="align-center"
            color="primary"
            @click="harvestDatesMenu = false"
            >OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-textarea v-model="notes" label="Notes"></v-textarea>
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field v-model="address_1" label="Address 1"></v-text-field>
      <v-text-field v-model="address_2" label="Address 2"></v-text-field>
      <v-text-field v-model="city" label="City"></v-text-field>
      <v-select
        v-model="country_id"
        :items="countries"
        item-value="id"
        item-text="country_name"
        label="Country"
        no-data-text="No Countries Available"
      ></v-select>
      <v-select
        v-if="filterById('states', country_id, 'country_id').length"
        v-model="state_id"
        :items="filterById('states', country_id, 'country_id')"
        item-value="id"
        item-text="title"
        label="State/Province"
        no-data-text="No States/Provinces Available"
      ></v-select>
      <v-text-field v-model="postal_code" label="Postal Code"></v-text-field>
      <v-text-field
        v-if="!isLoadingLatLng"
        v-model="latNum"
        label="Latitude"
        v-mask="latitudeMask"
      ></v-text-field>
      <v-text-field
        v-if="!isLoadingLatLng"
        v-model="longNum"
        label="Longitude"
        v-mask="longitudeMask"
      ></v-text-field>
      <lat-long-dialog
        @latLng="latLng"
        :latitude="latitude"
        :longitude="longitude"
      ></lat-long-dialog>
      <v-text-field
        v-model="storage_structure"
        label="Storage Structure"
      ></v-text-field>
      <v-text-field
        v-model="storage_dimensions"
        label="Storage Dimensions"
      ></v-text-field>
      <v-text-field
        v-model="itemYield"
        label="Yield / tonnage"
        v-mask="yieldMask"
      ></v-text-field>
      <v-checkbox v-model="is_active" label="Active"></v-checkbox>
    </v-col>
    <v-col cols="12">
      <v-divider />
      <div class="h6 py-2 font-weight-bold">Report Recipients</div>
      <SourcesCopyTo />
    </v-col>
  </v-row>
</template>

<script>
import rules from "@/utils/rules";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { mapActions, mapGetters, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import isEmpty from "lodash.isempty";
import LoadingBar from "@/components/loaders/LoadingBar";
import LatLongDialog from "@/components/sources/LatLongDialog";
import SourcesCopyTo from "@/components/sources/SourcesCopyTo";

const latitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  allowNegative: true,
  includeThousandsSeparator: false,
  integerLimit: 2,
  decimalLimit: 8
});
const longitudeMask = createNumberMask({
  prefix: "",
  suffix: "",
  allowDecimal: true,
  allowNegative: true,
  includeThousandsSeparator: false,
  integerLimit: 3,
  decimalLimit: 8
});
const yieldMask = createNumberMask({
  prefix: "",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: true
});

export default {
  name: "SourceEditFormFields",
  components: { SourcesCopyTo, LatLongDialog, LoadingBar },
  data() {
    return {
      loading: true,
      isLoadingLatLng: false,
      valid: false,
      rules: {
        required: [rules.required]
      },
      latitudeMask,
      longitudeMask,
      yieldMask,
      plantingDateMenu: false,
      purchaseDateMenu: false,
      estUseDateMenu: false,
      harvestDatesMenu: false
    };
  },
  computed: {
    ...mapGetters("baseData", [
      "countries",
      "states",
      "filterById",
      "feed_classes",
      "feed_types"
    ]),
    ...mapState("sources", ["detailItem"]),
    ...mapState("farms", { farm: "detailItem" }),
    ...mapFields("sources", [
      "detailItem.id",
      "detailItem.farm_id",
      "detailItem.feed_class_id",
      "detailItem.feed_type_id",
      "detailItem.title",
      "detailItem.number",
      "detailItem.latitude",
      "detailItem.longitude",
      "detailItem.address_1",
      "detailItem.address_2",
      "detailItem.city",
      "detailItem.country_id",
      "detailItem.state_id",
      "detailItem.postal_code",
      "detailItem.seed_brand",
      "detailItem.seed_genetics",
      "detailItem.additives",
      "detailItem.date_of_planting",
      "detailItem.date_of_purchase",
      "detailItem.storage_structure",
      "detailItem.storage_dimensions",
      "detailItem.yield",
      "detailItem.estimated_use_date",
      "detailItem.notes",
      "detailItem.is_active",
      "detailItem.farms_sources_harvest_dates"
    ]),
    ...mapFields("sources", {
      itemYield: "detailItem.yield"
    }),
    latNum: {
      get: function() {
        if (this.latitude !== null) {
          return Number(this.latitude);
        } else {
          return this.latitude;
        }
      },
      set: function(val) {
        this.latitude = val;
      }
    },
    longNum: {
      get: function() {
        if (this.longitude !== null) {
          return Number(this.longitude);
        } else {
          return this.longitude;
        }
      },
      set: function(val) {
        this.longitude = val;
      }
    },
    harvestDates: {
      get: function() {
        return this.detailItem.farms_sources_harvest_dates.map(
          date => date.harvest_date
        );
      },
      set: function(dates) {
        let harvestDates = [];
        if (Array.isArray(dates)) {
          dates.forEach(date => {
            harvestDates.push({ harvest_date: date });
          });
        }
        this.detailItem.farms_sources_harvest_dates = harvestDates;
      }
    }
  },
  methods: {
    ...mapActions("baseData", ["getFeedTypes", "getFeedClasses"]),
    filterFeedTypesByClass() {
      if (!this.feed_class_id) return [];
      return this.feed_types.filter(
        item => item.feed_class_id === this.feed_class_id
      );
    },
    // this is to assign the values coming from the maps dialog
    latLng(lat, lng) {
      // using this isLoadingLatLng attribute to remove the fields
      // with the masks - assignment isn't working otherwise
      this.isLoadingLatLng = true;
      this.$nextTick(() => {
        this.latitude = lat;
        this.longitude = lng;
      });
      this.$nextTick(() => {
        // and then we bring the fields back once the
        // assignment is done
        this.isLoadingLatLng = false;
      });
    }
  },
  mounted() {
    this.loading = true;
    if (isEmpty(this.latitude)) {
      this.latitude = this.farm.latitude;
    }
    if (isEmpty(this.longitude)) {
      this.longitude = this.farm.longitude;
    }
    Promise.all([this.getFeedClasses(), this.getFeedTypes()])
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style scoped></style>
