<template>
  <div>
    <SourcesCopyToSelect />
    <CopyToInvite :inviteCompleteCb="inviteCompleteCb" />
  </div>
</template>

<script>
import SourcesCopyToSelect from "@/components/sources/SourcesCopyToSelect";
import CopyToInvite from "@/components/copy_to/CopyToInvite";
import { mapFields } from "vuex-map-fields";
export default {
  name: "SourcesCopyTo",
  components: { SourcesCopyToSelect, CopyToInvite },
  computed: {
    ...mapFields("sources", ["detailItem.copy_tos"]),
    copy_tos_proxy: {
      get() {
        return this.copy_tos ? this.copy_tos.map(item => item.id) : [];
      },
      set(newVal) {
        this.copy_tos = [
          // Keep existing items as-is
          ...this.copy_tos.filter(item => newVal.indexOf(item.id) > -1),
          // Add any new values
          ...newVal
            .filter(id => !this.copy_tos.find(item => item.id === id))
            .map(id => ({ id }))
        ];
      }
    }
  },
  methods: {
    inviteCompleteCb: function(id) {
      this.copy_tos_proxy = [id, ...this.copy_tos_proxy];
    }
  }
};
</script>

<style scoped></style>
